.menu {
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.menu-item {
  position: absolute;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3598b6;
  font-weight: bold;
  text-decoration: none;
  transform-origin: 100px 100px; /* Center of the menu */
  font-size: 24px;
  background: #f5f5dc;
  padding: 10px;
  border-radius: 10px;
}
.menu-item:hover {
  color: #3598b6;
}

.dot {
  height: 10px;
  width: 10px;
  background: white;
  border-radius: 50%;
  margin-right: 20px;
}
