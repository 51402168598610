@media (max-width: 480px) {
  /* .about_us_section img {
    height: 200px !important;
    margin-bottom: 50px;
  } */
}

/* .about_us_section img {
  width: 95%;
  height: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
} */
.about_us_section p {
  font-family: "League Spartan", sans-serif;
  font-size: 20px;
  text-align: justify;
}
.about_us_section h4 {
  font-family: "League Spartan", sans-serif;
  font-size: 28px;
  color: grey;
  text-transform: capitalize;
}
