/* Footer Style */
.address h3 {
  font-family: "League Spartan", sans-serif;
  font-size: 30px;
  font-weight: bold;
}
.address p {
  font-family: "League Spartan", sans-serif;
  font-size: 18px;
}

/* Social Icon Style */

/* Style all font awesome icons */
.fa {
  padding: 10px 5px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  margin: 5px;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
  color: white;
}

/* Set a specific color for each brand */

/* Facebook */
.fa-facebook {
  background: #3b5998;
  color: white;
}
.fa-instagram {
  background: #000000;
  color: white;
}
.fa-linkedin {
  background: #0077b5;
  color: white;
}
.fa-whatsapp {
  background: #25d366;
  color: white;
}
.fa-envelope {
  background: black;
  color: white;
}
.fa-twitter {
  background: #1da1f2;
  color: white;
}
.fa-facebook-messenger {
  color: #fff;
  background-image: linear-gradient(to top right, #168aff, #9f33ff, #ff6070);
}
.fa-youtube {
  color: white;
  background: red;
}

.domain h3 {
  color: #3c69bd;
  font-family: "League Spartan", sans-serif;
  font-size: 2vw;
  font-weight: bold;
}

.info_section i {
  border: 1px solid grey;
  border-radius: 50%;
  background-color: rgb(244, 241, 241);
  padding: 10px;
  margin-right: 1vw;
}
.footer_right_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
