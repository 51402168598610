.single_product_card {
  text-decoration: none;
  color: black;
  text-align: center;
  position: relative;

  border-radius: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
  background: #fff;
  margin: 10px;
}
.single_product_card img {
  height: 200px;
  width: 200px;
  padding: 20px;
}
.single_product_card:hover {
  color: #3b81b1;
  cursor: pointer;
  border-style: double;
  border-color: #3b81b1;
}

.product_card_btn {
  background: #3b81b1;
  color: white;
}
.product_card_btn:hover {
  background: #3b81b1;
  color: white;
}

.single_product_card .card-footer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.single_product_card h6 {
  font-size: 14px;
  height: 80px;
  padding: 10px;
}
