.section_title {
  font-family: "League Spartan", sans-serif;
  font-size: 42px;
  color: navy;
  text-align: center;
  margin: 50px 0;
}

.our_products_container {
}
