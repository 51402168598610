@media (max-width: 480px) {
  /* .single_slide {
    height: 60vh !important;
  }
  .slider_title {
    font-size: 36px !important;
  } */
}
.hero_slider {
}
.single_slide {
  height: 100vh;
  width: 100vw;
}
.slider_img {
  height: 100%;
  width: 100%;
  position: relative;
}
.slider_title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Poppins", sans-serif;
  font-size: 3vw;
  color: #ffffff;
  text-shadow: 3px 3px #000000;
  backdrop-filter: blur(5px);
}

.swiper-button-prev,
.swiper-button-next {
  color: white;
  font-size: 24px;
  font-weight: bold;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Add a semi-transparent background */
  backdrop-filter: blur(10px);
}

.swiper-pagination-bullet {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Add a semi-transparent background */
  width: 15px;
  height: 15px;
  border-radius: 0;
}
