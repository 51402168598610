.brand_list_menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.brand_list_item {
  font-size: 14px;
  color: black;
  text-decoration: none;
  margin: 20px;
}
.brand_list_item img {
  width: 120px;
  height: 50px;
}
.brand_list_item p {
  width: 200px;
  font-size: 18px;
  color: black;
  background: #eee;
  padding: 10px;
  border: 1px solid gray;
  text-align: center;
}
.brand_list_item p:hover {
  opacity: 0.5;
}
