.live_chat_section_wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 10px;
}
.all_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.social_chat_btn {
  border-radius: 50%;
  margin: 10px 0;
  font-size: 18px;
  padding: 10px;
}
.trigger_btn {
  background: #3b81b1;
  padding: 10px;
  border-radius: 50%;
  color: white;
  text-align: center;
}
