.stat_container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.stat_point {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.stat_icon i {
  font-size: 48px;
  color: #3598b6;
  margin: 0 50px;
  width: 50px;
}
.stat_container h2 {
  font-family: "League Spartan", sans-serif;
  font-size: 36px;
  color: #3598b6;
}
.stat_container p {
  font-family: "League Spartan", sans-serif;
  color: grey;
  font-size: 18px;
}
