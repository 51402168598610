.filter-container {
  width: 20vw;

  margin-top: 25%;
}

.list_item {
  margin: 5px 0;
  cursor: pointer; /* Makes the toggle icon clickable */
  font-size: 14px;
}

.toggle_button {
  margin-right: 8px;
  cursor: pointer;
  float: left;
  margin-top: 8px;
}

.sub_list_group {
  margin-left: 20px; /* Indent subcategories */
}

.sub_list_item {
  font-style: italic;
}

.list_item_link {
  color: black; /* Custom text color */
  text-decoration: none; /* Remove underline */
  padding: 7px; /* Padding around the link */
  border: 1px solid transparent; /* Transparent border for hover effect */
  transition: all 0.3s ease; /* Smooth transition for hover effect */

  display: block;
}

.list_item_link:hover {
  color: #3598b6; /* Change color on hover */
  border-color: lightgray; /* Add border color on hover */
  text-decoration: none; /* Add underline on hover */
}
