.contact_section {
}

.contact_form {
  padding: 5%;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 60vh;
}

.map_container {
  width: 100%;
  height: 40vh;
  margin: 50px 0;
}

.contact_btn {
  border-color: #3598b6;
}
.contact_btn:hover {
  background: #3598b6;
}
