.hero_product_category {
  background: rgb(244, 243, 243);
  padding: 30px 50px;
}
.hero_product_category h1 {
  font-family: "League Spartan", sans-serif;
  font-size: 30px;
  color: #3598b6;
  text-align: center;
}

.hero_product_category .more_btn {
  background: #3598b6;
  color: white;
}
