.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background: rgba(51, 51, 51, 0.5);
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.navbar-links li {
  position: relative;
  margin: 0;
}

.navbar-links .parent_a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  transition: color 0.3s ease;
  padding: 2rem 1rem;
}

.navbar-links .parent_a:hover {
  background: #3598b6;
  color: #fff;
}

.logo_container img {
  width: 200px;
  padding: 10px;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  width: 30px;
  height: 25px;
  justify-content: space-between;
}

.menu-icon .line {
  width: 100%;
  height: 4px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.menu-icon .line.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon .line.open:nth-child(2) {
  opacity: 0;
}

.menu-icon .line.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-links.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 70px;
  right: 0;
  background-color: #333;
  width: 100%;
  text-align: right;
}

.has-submenu {
  position: relative;
}

.nested-menu {
  list-style-type: none;
  padding: 2% 0;
  margin: 0;
  background-color: #444;
  position: absolute;
  top: 200%;
  left: -500%;
  display: none;
}

.has-submenu:hover .nested-menu {
  display: flex;
}

.nested-menu li {
  padding: 1rem;
  min-width: 5vw;
}
.nested-menu li:hover {
  background: #3598b6;
  cursor: pointer;
}

.nested-menu a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .menu-icon {
    display: flex;
  }
}

@media (max-width: 480px) {
  .logo_container img {
    width: 150px;
  }

  .navbar {
    padding: 0 1rem;
  }

  .navbar-links a {
    padding: 1rem;
  }
}
