@media (max-width: 480px) {
  .service_card {
    margin: 3vh 10vw !important;
  }
}

.service_card {
  font-family: "League Spartan", sans-serif;
  height: 51vh;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  text-align: center;
  margin: 3vh 0.5vw;
  padding: 0;
}

.service_card:hover {
  background: #3598b6;
  color: #fff;
  cursor: pointer;
}

.service_card img {
  width: 100%;
  height: 60%;
}

.service_card p {
  font-size: 22px;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service_card a {
  font-size: 16px;
  text-decoration: none;
  color: inherit;
}
