.brand_slider_container {
  display: flex;
  align-items: center;
}
.fa-caret-right,
.fa-caret-left {
  color: #0a7291;
  font-size: 100px;
  text-decoration: none;
}
.fa-caret-right:hover,
.fa-caret-left:hover {
  cursor: pointer;
  color: black;
}
.brand_slider {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}

.logo_img {
  width: 200px;
  margin: 0 50px;
}

.logo_list_img {
  width: 120px;
  height: 50px;
  margin: 50px;
}

.toggle_btn {
  border-color: #0a7291;
  color: #000;
}
.toggle_btn:hover {
  background: #0a7291;
  color: #fff;
}
